@import '../../../styles/base/base-colors.scss';

.upArrow{
  fill:$secondaryThemeColor;

}
.circleArrow{
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  border: 2px solid $secondaryThemeColor;
  height: 40px;
  position: fixed;
  bottom: 60px;
  width: 40px;
  padding: 5px;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  transition-property: opacity, right;
  cursor: pointer;
  right: -75px;
  &>div{
    top: 11px;
    position: relative;
  }
}
