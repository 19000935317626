
//background colors
$backgroundColor: #f2f2f3;
$bottomBorder: 0;

//fonts
$linkColor: #3b435c;
$fontSize:11px;
$fontWeight:400;

.topRow
{
  width: 100%;
  padding: 5px 0;
  background-color: $backgroundColor;
  line-height: 22px;
  border-bottom: $bottomBorder;
  display: flex;
  font-size:$fontSize;
  justify-content: space-between;
  >div{
    padding: 5px;
    display: flex;
    .infoIcon{
      height: 10px;
      width: 10px;
    }
  }

  #leftContact{
    align-self: flex-start;
    margin-left: 10px;
  }
  #rightContact{
    align-self: flex-end;
    margin-right: 10px;
  }
  a{
    color:$linkColor;
    text-decoration: none;
  }
}
