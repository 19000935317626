@import url('https://fonts.googleapis.com/css?family=Antic|Lato:300,400,700&display=swap');
//@import './base-layout.scss';

//FONT FACES
$primaryFont: 'Lato';
$secondaryFont: 'Antic';
$tertiaryFont: 'Lato';
$quaternaryFont: '';
$quinaryFont: '';


$secondaryFullFont: 300 12px Lato, serif;

//FONT SIZES
$bodyFontSize: 12px;
$primaryBodyFontSize: 8px;
$inputFontSize:14px;

//HEADER
$fullHeaderFont: 400 13px $primaryFont, serif;

//FOOTER
$fullFooterFont: 300 16px $primaryFont, serif;

