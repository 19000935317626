@import '../../../styles/base/base-colors.scss';
@import '../../../styles/base/base-typography.scss';
@import '../../../styles/base/base-layout.scss';

//logo
$logoHeight:70px;

.header{
    color: white;
    padding: 10px;
    background-color: $headerBackgroundColor;
    display:flex;
    justify-content: space-evenly;
    .headerContent{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        max-width:$websitePageWidth;
        .logoContainer{
            justify-content: flex-start;
            a{
                color: #FFF;
                text-decoration: none;
                height: 100%;
                display: block;
                .logo{
                    height: $logoHeight;
                    width: 100%;
                }
            }
            .active{
                .tkas{
                    &::before{
                        color: #27a3bf;
                    }
                }
            }
        }
        .linksContainer{
            justify-content: center;
            background-color: $linkBlockBackgroundColor;
            padding: 0 20px;

            .linksTilesContainer{
                height: 100%;
            }

        }
    }
}
@media screen and (max-width: $websitePageWidth)
{

    .linksContainer{
        display: none;
    }
}
@media screen and (max-width: $mobilePageWidth)
{
    .topRow{
        display: none;
    }

    .modalOverlay{
        top: 92px;
    }
}

