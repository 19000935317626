@import '../../../styles/base/base-colors.scss';
@import '../../../styles/base/base-typography.scss';
@import '../../../styles/base/base-layout.scss';
@import '../../../styles/mixins/mixins-common-components.scss';


//logo
$logoHeight:70px;
//padding
$bottomPadding: 30px;
$tilePadding: 3px 0;

footer {
    background-color: $footerBackgroundColor;
    color: $textColor;
    padding: ($bottomPadding / 2) 0 0;
    display: block;

    .footerLow{
        .list{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            width: calc( 100% - 60px );
            padding:  ($bottomPadding / 2) $bottomPadding;
            color:$bottomTextColor;
            a {
                color: $footerLinkColor;
                transition: color 350ms;
                margin: 0;
                padding: 0;

                line-height: 20px;
                text-decoration: none;
                &:hover {
                    color: $footerLinkHoverColor;
                    transition: color 350ms;
                }
            }
        }

    }

    .footerContainer{
        display: grid;
        align-content: space-between;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 10px;
        width: $websitePageWidth;
        margin:auto;
        font: $fullFooterFont;
        max-width: 100%;
       .footerTile{
           ul{
               padding-left:20px;
           }
           //background-color: $footerTileColour;

           padding:10px;
           .list li {
               list-style: none;
               padding:$tilePadding;
           }
           .footerTitle{
               padding-bottom: 8px;
           }
           .footerImg{
                img{
                    max-width: 80%;
                    min-width:200px;
                }
           }
           .list a {
               color: $footerLinkColor;
               transition: color 350ms;
               margin: 0;
               padding: 0;
               list-style: none;
               line-height: 20px;
               text-decoration: none;
               &:hover {
                   color: $footerLinkHoverColor;
                   transition: color 350ms;
               }
           }
        }

    }

}

@media screen and (max-width: $tabletPageWidth)
{
    footer{
        .footerContainer{
            width: 100%;
            grid-template-columns: repeat(2, 1fr);
            .footerTile{
                ul {
                    padding-left: 10px !important;
                }
            }
        }
    }
}

@media screen and (max-width: $mobilePageWidth)
{
    footer{
        .footerContainer{
            width: 100%;
            grid-template-columns: repeat(1, 1fr);
            justify-content: center;
            justify-items: center;
            .footerTile{
                ul {
                    padding-left: 5px !important;
                }
            }
        }
    }
}
