@import './base-typography.scss';
@import './base-colors.scss';

//page properties
$websitePageWidth: 1310px;
$tabletPageWidth: 768px;
$mobilePageWidth: 450px;


//poster properties
$posterPadding:64px;
$posterWrap:wrap;
$posterAlign:center;

//change this - temporary
$tileFlexWidth:190px;

//todo dont do this, dont import typo and colors in here, change this to a mixin
.bodyFlexContainer{
  display: flex;
  justify-content: center;
  width: $websitePageWidth;
  margin: auto;


  .tilesFlex{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    &>div{
      width: $tileFlexWidth;
      margin: 10px;
      padding:10px;
      background-color: $generalBackgroundColor;
      text-align: center;
      font:$secondaryFullFont;
    }

  }

}

@media screen and (max-width: $mobilePageWidth)
{
  html{
    body{
      #root{
        position: relative;
        top: 93px;
        .content{

          .header{
            justify-content: flex-start;
            position: fixed;
            right: 0;
            left: 0;
            top: 0;
            z-index: 3;
            .headerContent{
              position: relative;
              left: 0;
            }
          }
        }
      }

    }
  }

}

@media screen and (max-width: $websitePageWidth) {
  .bodyFlexContainer{
    width: 80%;
  }
}
//fixes issues with small res
@media screen and (max-width: 280px) {
  //body{
  //  display: grid;
  //  grid-template-columns: 1fr;
  //  padding: 10px;
  //}
}
