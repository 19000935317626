//THEMES
$primaryThemeColor:#367ec1;
$secondaryThemeColor:#f26b27;

//FONTS -----
$primaryTextColor: #636363;
$secondaryTextColor: #2b2b2b;

$generalBackgroundColor: #f9f9f9;

$headingColor: #5f5f5f;

$subHeadingColor: #6d6d6d;

$primaryLinkColor: #0156b1;
$primaryLinkHoverColor: darken($primaryLinkColor, 30%);

$linkActiveColor: #bf0109;

$secondaryLinkColor: #282b30;
$secondaryLinkHoverColor: #0f6599;

//BUTTONS -----
$primaryButtonColor: #0965b3;
$secondaryButtonColor: #ed6000;
$tertiaryButtonColor: #3f4040;

$lineColor: #999999;
$lineColorUnder: #ededed;
$iconDisable: #d7d7d7;
$alternativeRow: #fafafa;
$iconActive: #3cb896;

//BODY COLOR -----
$primaryBodyColor: #cc0000;
$secondaryBodyColor: #0f6599;
$tertiaryBodyColor: #EFEFEF;
$quaternaryBodyColor:#EFEFEF;
$quinaryBodyColor:#EFEFEF;

//FOOTER-----

//font colors
$footerLinkColor: #909298;
$footerLinkHoverColor: darken($footerLinkColor, 10%);;
$bottomTextColor: #707a83;
$textColor: #FFFFFF;
//backgroundColors
$footerBackgroundColor: #212121;
$footerTileColour: #e6e4e4;
$backgroundShade:#f7f7f7;

//LINKS
$primaryLinkColor: #0356ad;
$primaryVisitedLinkColor: darken($primaryLinkColor, 10%);
$primaryHoverLinkColor: lighten($primaryLinkColor, 10%);
$primaryActiveLinkColor: lighten($primaryLinkColor, 20%);


//HEADER-----

//background colors
$headerBackgroundColor: #f9f9f9;
$linkBlockBackgroundColor: #f9f9f9;

//HEADER LINK TILES
$linkTileBackgroundColor: #f9f9f9;
$linkTileActiveBackgroundColor: #f9f9f9;

//HEADER FONT COLORS
$headerLinkTileColor: #21252a;
$headerLinkTileActiveColor: #ea5d17;
$headerLinkTileHoverColor:darken($headerLinkTileColor, 10%);

//underlines
$linkTileActiveUnderline: inset 0 -1px 0 $headerLinkTileActiveColor;
$linkTileHoverUnderline: inset 0 -1px 0 $headerLinkTileHoverColor;
